<template>
    <div class="container block">

        <div v-if="this.settings" class="box">
          <article class="media">
            <div class="media-left">
              <figure class="image is-128x128">
                <img src="https://www.makeyoutubevideo.com/assets/img/avatar.jpg" alt="Image">
              </figure>
            </div>
            <div class="media-content">
              <div class="content">
                <p>
                  <small>Id: </small><strong>{{ this.settings.id }}</strong>
                  <br>
                  <small>Name: </small><strong>{{ this.settings.name }}</strong>
                  <br>
                  <small>Email: </small><strong>{{ this.settings.email }}</strong>
                  <br>
                  <!-- <small>ESP: </small><strong v-on:click="getEspUsers(getEspByEmail(settings.email))">{{ getEspByEmail(this.settings.email) }}</strong> -->
                  <small>ESP: </small><strong class="pointer has-text-info" @click="esp.isOpen = true">{{ getEspByEmail(this.settings.email) }}</strong>
                  <b-modal v-model="esp.isOpen" :width="320" scroll="keep">
                    <div class="card has-text-left">
                        <div class="card-content">
                            <div class="content">
                              <b-field label="Domain">
                                <b-input disabled :value="getEspByEmail(this.settings.email)"></b-input>
                              </b-field>
                              <b-field label="Status" class="">
                                <p class="control buttons has-addons">
                                  <b-button class="card-footer-item" :type="this.esp.espStatus === 'blocked' ? 'is-danger' : ''" @click="blockUserEsp">Blocked</b-button>
                                  <b-button class="card-footer-item" :type="this.esp.espStatus === 'trusted' ? 'is-success' : ''" @click="trustUserEsp">Trusted</b-button>
                                  <b-button class="card-footer-item" type="" @click="forgetUserEsp">Forget</b-button>
                                </p>
                              </b-field>
                              <b-field label="User(s) count">
                                <p>{{ this.esp.espCount }}</p>
                              </b-field>
                            </div>
                        </div>
                    </div>
                  </b-modal>
                  <br>
                  <small>ESP status: </small><strong :class="this.esp.espStatus==='blocked' ? 'has-text-danger':'has-text-success'">{{ getEspStatus(getEspByEmail(this.settings.email)) }} {{ this.esp.espStatus }}</strong>
                  <br>
                  <b-button class="block-btn" type="is-danger" @click="showAnotherUsers()">Show another {{ getEspUsersCount(getEspByEmail(this.settings.email)) }} {{ this.esp.espCount }} users </b-button>
                  <br>
                  <small>Register Ip: </small><strong>{{ this.settings.registerIp }}</strong>
                   <br>
                  <small>Register Service: </small><strong>{{ this.settings.registerServiceName }}</strong>
                   <br>
                  <small>Register Time: </small><strong>{{ new Date(this.settings.registerTime).toLocaleDateString() }}</strong>
                   <br>
                  <small>Verified: </small><strong class="verified-span">{{ this.settings.verified }}</strong>
                </p>
                <div class="status-box">
                    <div>
                        <small>Status</small>
                        <span v-bind:class="this.settings.status" type="is-primary" outlined>{{ this.settings.status }}</span>
                    </div>
                    <div>
                        <small>Role</small>
                        <span v-bind:class="this.settings.role" type="is-primary" outlined>{{ this.settings.role }}</span>
                    </div>
                    <div>
                        <small>Type</small>
                        <span v-bind:class="getUserType()" type="is-primary" outlined>{{ getUserType() }}</span>
                    </div>
                </div>
              </div>
            </div>
          </article>
        </div>

        <usersites :user-id="this.settings && this.settings.id" v-on:update-user-type="setUserType($event, 'sites')"></usersites>
        <userlicenses :user-id="this.settings && this.settings.id" v-on:update-user-type="setUserType($event, 'user')"></userlicenses>

        <div v-if="this.settings">
            <b-button class="block-btn" @click="deleteUser">DELETE USER AND SITES</b-button>
            <b-button v-if="this.settings.status === 'blocked'" class="block-btn" type="is-warning" @click="unblockUser">UNBLOCK</b-button>
            <b-button v-else class="block-btn" type="is-danger" @click="blockUser">BLOCK USER AND DELETE SITES</b-button>
        </div>

    </div>
</template>

<script>
    import Client from '../libs/mbr-client.js';
    import query from '../libs/query.js';
    import usersites from './UserSites.vue';
    import userlicenses from './UserLicenses.vue';

    const mbrClient = new Client("https://u.8b.com/api/v1/");

    export default {
        name: 'UserCard',
        components: { usersites, userlicenses },
        props: ['settings'],
        watch: {
            'settings.id': function(id) {
                if (!id) return;
                this.premiumStatus = [];
            }
        },
        methods: {
            getEspByEmail(email) {
                return email.split('@').pop();
            },
            getUserType() {
                return this.premiumStatus.length ? 'premium' : 'free';
            },
            setUserType(type, source) {
                this[type === 'free'
                    ? 'revokePremiumStatus'
                    : 'assignPremiumStatus'
                ](source);
            },
            assignPremiumStatus(source) {
                this.premiumStatus.includes(source) || this.premiumStatus.push(source);
            },
            revokePremiumStatus(source) {
                this.premiumStatus = this.premiumStatus.filter((item) => item !== source);
            },
            blockUser() {
                let _this = this;
                const loadingComponent = this.$buefy.loading.open();

                mbrClient.ajax({
                    method: 'PUT',
                    url: `https://u.8b.com/api/v1/users/${ _this.settings.id }`,
                    data: { "status":"blocked" }
                })
                .then(function(res) {
                    _this.settings = res;
                    //delete sites
                    console.log('start delete sites');
                    query(`/v1/users/${ _this.settings.id }/sites`, { method: 'GET' })
                    .then(function(sites) {
                        let promiseArr = [];
                        console.log(`${ sites.items.length } sites detected`);
                        for (let i = 0; i < sites.items.length; i++){
                            debugger
                            promiseArr.push(query(`/v1/sites/${ sites.items[i].id }`, { method: 'DELETE', headers: { 'X-8b-Deny-Subdomain': 'true' } }));
                        }
                        Promise.all(promiseArr)
                        .then((values) => {
                            loadingComponent.close();
                            _this.$buefy.notification.open({
                                    duration: 3000,
                                    message: `The user was successfully blocked. All user's sites removed.`,
                                    position: 'is-top',
                                    type: 'is-success'
                                });
                        })
                        .catch(err => { loadingComponent.close(); console.log(err); })
                    })
                    .catch(err => {
                        loadingComponent.close();
                        console.log(err);
                    });
                })
                .catch(err => {
                    console.log(err);
                    loadingComponent.close();
                    _this.$buefy.notification.open({
                            duration: 3000,
                            message: err.message,
                            position: 'is-top',
                            type: 'is-danger'
                        });
                });
            },
            unblockUser() {
                let _this = this;
                const loadingComponent = this.$buefy.loading.open();

                mbrClient.ajax({
                    method: 'PUT',
                    url: `https://u.8b.com/api/v1/users/${ _this.settings.id }`,
                    data: { "status":"active" }
                })
                .then(function(res) {
                    _this.settings = res;
                    loadingComponent.close();
                    _this.$buefy.notification.open({
                            duration: 3000,
                            message: `The user was successfully unblocked`,
                            position: 'is-top',
                            type: 'is-success'
                        });
                })
                .catch(err => {
                    console.log(err);
                    loadingComponent.close();
                    _this.$buefy.notification.open({
                            duration: 3000,
                            message: err.message,
                            position: 'is-top',
                            type: 'is-danger'
                        });
                });
            },
            deleteUser() {
                let _this = this;
                let iamready = confirm('Are you sure you want to delete the user and all their sites?');
                if (!iamready) return;

                const loadingComponent = this.$buefy.loading.open();

                mbrClient.ajax({
                    method: 'DELETE',
                    url: `https://u.8b.com/api/v1/users/${ _this.settings.id }`
                })
                .then(function(res) {
                    loadingComponent.close();
                    _this.$buefy.notification.open({
                            duration: 3000,
                            message: `The user was successfully deleted. All user's sites will be removed soon.`,
                            position: 'is-top',
                            type: 'is-success'
                        });
                })
                .catch(err => {
                    console.log(err);
                    loadingComponent.close();
                    _this.$buefy.notification.open({
                            duration: 3000,
                            message: err.message,
                            position: 'is-top',
                            type: 'is-danger'
                        });
                });
            },
            returnPromiseResult(result) {
                return result;
            },
            showAnotherUsers() {
              this.$emit('update-user-type', {type: 'esp', esp: this.getEspByEmail(this.settings.email)});
            },
            getEspUsers(esp) {
                mbrClient.getEspUsers(esp).then(res => {
                    this.esp.espCount = res;
                });
            },
            getEspUsersCount(esp) {
                mbrClient.getEspUsersCount(esp).then(res => {
                    this.esp.espCount = res;
                });
            },
            getEspStatus(esp) {
                mbrClient.getEspStatus(esp).then(res => {
                    this.esp.espStatus = res;
                });
            },
            blockUserEsp() {
                const loadingComponent = this.$buefy.loading.open();
                mbrClient.blockEsp(this.getEspByEmail(this.settings.email)).then(() => {
                    this.$buefy.notification.open({
                        duration: 3000,
                        message: 'The ESP was successfully blocked',
                        position: 'is-top',
                        type: 'is-success'
                    });
                }, (err) => {
                    this.$buefy.notification.open({
                        duration: 3000,
                        message: err.message,
                        position: 'is-top',
                        type: 'is-danger'
                    });
                }).finally(() => {
                    loadingComponent.close();
                });
            },
            trustUserEsp() {
                const loadingComponent = this.$buefy.loading.open();
                mbrClient.trustEsp(this.getEspByEmail(this.settings.email)).then(() => {
                    this.$buefy.notification.open({
                        duration: 3000,
                        message: 'The ESP was successfully trusted',
                        position: 'is-top',
                        type: 'is-success'
                    });
                }, (err) => {
                    this.$buefy.notification.open({
                        duration: 3000,
                        message: err.message,
                        position: 'is-top',
                        type: 'is-danger'
                    });
                }).finally(() => {
                    loadingComponent.close();
                });
            },
            forgetUserEsp() {
                const loadingComponent = this.$buefy.loading.open();
                mbrClient.forgetEsp(this.getEspByEmail(this.settings.email)).then(() => {
                    this.$buefy.notification.open({
                        duration: 3000,
                        message: 'The ESP was successfully forgotten',
                        position: 'is-top',
                        type: 'is-success'
                    });
                }, (err) => {
                    this.$buefy.notification.open({
                        duration: 3000,
                        message: err.message,
                        position: 'is-top',
                        type: 'is-danger'
                    });
                }).finally(() => {
                    loadingComponent.close();
                });
            },
        },
        data() {
            return {
                esp: {
                  isOpen:false,
                  showAnother:false
                },
                premiumStatus: []
            };
        }
    }
</script>

<style scoped>
    .container.block{
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }
    .box{
        margin: 30px;
        max-width: 500px;
        min-width: 400px;
    }
    .media-left{
        display: flex;
        flex-direction: column;
    }
    .block-btn{
        padding: 5px 25px;
        margin: 5px;
    }
    .verified-span{
        border-radius: 3px;
        padding: 2px 15px;
        background-color: #f1f1f1;
    }
    .status-box{
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: space-evenly;
    }
    .status-box > div{
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        text-align: center;
    }
    .status-box span{
        border-radius: 5px;
        padding: 0px 10px;
        color: #fff;
    }
    .admin,.moderator,.support{
        background-color: #75adf3;
    }
    .member,.active,.free{
        background-color: #58c36f;
    }
    .blocked,.deleted{
        background-color: #ff3860;
    }
    .premium{
        background-color: #ffdd57;
        color: #000 !important;
    }
    .user-sites{
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;-webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        -o-flex-direction: row;
        flex-direction: row;
        flex-wrap: wrap;
        width: 400px;
        padding: 0px 20px 20px;
    }
    .user-sites__item{
        display: flex;
        flex-basis: 110px;
        padding: 15px;
        margin: 5px;
        border: 1px solid red;
        border-radius: 5px;
    }
    .pointer {
        cursor: pointer;
    }
</style>
