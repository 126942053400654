import Vue from 'vue';
import App from './app.vue';
import router from './router';
import GAuth from 'vue-google-oauth2';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

Vue.use(Buefy);

Vue.config.productionTip = false;

const gauthOption = {
 	clientId: '127029165104-0mr0eo7cltaco9bq03lhj3rn03pc8bej.apps.googleusercontent.com',
 	scope: 'openid email https://www.googleapis.com/auth/userinfo.email'
};

Vue.use(GAuth, gauthOption);

new Vue({
	data: {},
	router,
 	render: h => h(App),
}).$mount('#app');
