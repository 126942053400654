<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import AdminPanel from './components/AdminPanel.vue'
  import LoginForm from './components/LoginForm.vue'

  export default {
    name: 'App',
    components: {
      AdminPanel,
      LoginForm
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 90px;
  }
  .notices.is-top {
      padding: 15px;
  }
</style>
