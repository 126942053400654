<template>
    <section class="admin-panel">
        <div class="container">
            <form>
                <span class="label">User name or email</span>
                <b-field>
                    <input class="input-name" v-model="userName" type="text">
                    <b-button class="submit-btn" type="is-info" @click="findUser(userName)">Find</b-button>
                </b-field>
            </form>
            <form>
                <span class="label">Site id</span>
                <b-field>
                    <input class="input-name" v-model="siteId" type="text">
                    <b-button class="submit-btn" type="is-success" @click="findUserBySite">Find</b-button>
                </b-field>
            </form>
        </div>
        <div class="columns">
            <div class="column">
                <AllSites :sitesState="allSitesSource" :selectUser="findUser"></AllSites>
            </div>
            <div class="column">
                <usersitesiframe :user-id="cardData && cardData.id"></usersitesiframe>
            </div>
            <div class="column">
                <usercard v-on:update-user-type="setSiteSource($event)" v-show="cardData" :settings="cardData"></usercard>
                <!-- <usercard v-on:update-user-type="setUserType($event, 'sites')" v-show="cardData" :settings="cardData"></usercard> -->
            </div>
        </div>
    </section>
</template>

<script>
    import Client from '../libs/mbr-client.js';
    import usercard from './UserCard.vue';
    import usersitesiframe from './UserSitesIframe.vue';
    import query from '../libs/query.js';
    import AllSites from './AllSites.vue';

    const mbrClient = new Client("https://u.8b.com/api/v1/");

    export default {
        name: 'AdminPanel',
        props: {
            msg: String
        },
        methods: {
            setSiteSource(typeObject) {
                this.allSitesSource.type = typeObject.type
                if (typeObject.esp) this.allSitesSource.esp = typeObject.esp
                else delete this.allSitesSource.esp
            },
            findUser(id) {
                if (String(id).trim() === '') {
                    this.cardData = false;
                    return;
                }
                let _this = this;
                const loadingComponent = this.$buefy.loading.open();
                mbrClient.ajax({
                    method: 'GET',
                    url: `https://u.8b.com/api/v1/users?filter[q]=${ id }`
                })
                .then(function(res) {
                    loadingComponent.close();
                    _this.cardData = res.items[0];
                    if (res.count === 0) {
                        _this.$buefy.notification.open({
                            duration: 3000,
                            message: `Nothing found`,
                            position: 'is-top',
                            type: 'is-danger'
                        });
                    }
                    _this.$emit('user-data-updated');
                })
                .catch(err => {
                    loadingComponent.close();
                    console.log(err);
                    _this.$buefy.notification.open({
                            duration: 3000,
                            message: err.message,
                            position: 'is-top',
                            type: 'is-danger'
                    });
                    if (err.message === "Access denied"){
                        alert('You must be an administrator or moderator. Access denied.');
                        return;
                    }
                    this.$router.push({ path: 'login'});
                });
            },
            findUserBySite() {
                if (String(this.siteId).trim() === '') {
                    this.cardData = false;
                    return;
                }
                let _this = this;
                const loadingComponent = this.$buefy.loading.open();
                query(`/v2/sites?filter[q]=${ this.siteId }`, { method: 'GET' })
                .then(function(res) {
                    if (!res.count) throw new Error('Nothing found');
                    loadingComponent.close();
                    _this.findUser(res.items[0].owner.id);
                })
                .catch(err => {
                    this.cardData = false;
                    loadingComponent.close();
                    console.log(err);
                    _this.$buefy.notification.open({
                            duration: 3000,
                            message: err.message,
                            position: 'is-top',
                            type: 'is-danger'
                    });
                    if (err.message === "Access denied"){
                        alert('You must be an administrator or moderator. Access denied.');
                        return;
                    }
                });
            }
        },
        components: {
            usercard,
            AllSites,
            usersitesiframe
        },
        created: function () {
            mbrClient.isAuthorized()
            .then(res => {
                console.log('logined');
            })
            .catch(err => {
                this.$router.push({ path: 'login'});
            });
        },
        data() {
            return {
                allSitesSource: { type: 'all', esp: null},
                cardData: false,
                userName: '',
                siteId: ''
            }
        }
    }
</script>

<style scoped>
    .container{
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .label{
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        color: gray;
        margin-top: 5px;
    }
    .submit-btn{
        margin-left: 10px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .input-name{
        min-width: 250px;
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
        border: 1px solid #dbdbdb;
        background-color: white;
        border-radius: 4px;
        color: #363636;
        padding: 0 10px;
        font-size: 16px;
    }
    .input-name:focus{
        outline: 1px;
    }
</style>
