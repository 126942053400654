<template>
    <div class="row wrapper wrapper_padding wizard" :class="authorized ? '' : 'align-items-center'">
        <div class="col">
            <div class="b-loader text-center mt-4 mb-4" v-if="!load">
                <!-- <ball-pulse-loader color="#18bc9c"></ball-pulse-loader> -->
            </div>
            <login_form v-on:signedin="authorize" v-if="!authorized"></login_form>
            <!-- <div class="mt-4 mb-4" v-else>
                <p>You are authorized!</p>
            </div> -->
        </div>
    </div>
</template>

<script>
    import BallPulseLoader from 'vue-loaders/dist/loaders/ball-pulse.js';
    import login_form from "./LoginForm.vue";

    function getArgv(name){
        var params = (location.search? location.search.substr(1) + '&': '') + (location.hash? location.hash.substr(1): '');
        var argv = {};
        params
            .split('&')
            .map(item => {
                let name = decodeURIComponent(item.split('=')[0]||'');
                if (name)
                    argv[name] = decodeURIComponent((item.split('=')[1] ||'').replace(/\+/g, '%20'));
            });

        if (argv.state)
            try {
                Object.assign(argv, JSON.parse(decodeURIComponent(argv.state)));
            }
            catch(e){}

        return name? argv[name]: argv;
    }

    export default {
        data() {
            return {
                open: true,
                load: false,
                authorized: false
            }
        },
        components: { login_form, BallPulseLoader },
        methods: {
            authorize() {
                this.authorized = true;
                console.warn('authorized, reload...');
                run_redirect(location.href.substr(0, location.href.lastIndexOf('/')));
            }
        },
        created: function () {
            login_form
            .checkAuthorized()
            .then(ok => {
                if (ok && (location.search || location.hash)) {
                    return login_form.getProfile()
                }
                else {
                    this.authorized = false;
                }
            })
            .then(data => {
                this.load = true;

                if (data){
                    this.authorized = true;
                    run_redirect(location.origin);
                }
                else {
                    this.authorized = false;
                }
            })
            .catch(err => {
                    this.load = true;
                    this.authorized = false;
            });
        }
    };

    function run_redirect(url) {
        let return_to = getArgv('return_to');
        if (return_to) {
            login_form.getAuthToken()
            .then(token=>{
                location.href = return_to + '?bearer=' + encodeURIComponent(token.access_token);
            })
        }
        else
            location.href = url;
    }
</script>
<style>
body{
    background-color:white;
}
</style>