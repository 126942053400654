<template>
    <div class="user-licenses">
        <h2 v-show="userLicenses" class="user-licenses__title">Licenses</h2>
        <div v-if="userLicenses" class="user-licenses__item" v-for="item in userLicenses">
            <div>{{ item.plan.title }}</div>
            <div class="user-licenses__period"><code>{{ item.created }}</code> — <code>{{ item.expired }}</code></div>
            <div v-show="item.isActual" class="user-licenses__buttons">
                <button type="button" class="button submit-btn is-danger" @click="cancelLicense(item.id)">CANCEL</button>
            </div>
        </div>
        <div v-if="plans" class="user-licenses__item user-licenses__form">
            <b-field label="Plan">
                <b-select v-model="newLicense.planId" class="user-licenses__select" placeholder="Select plan">
                    <option
                        v-for="plan in plans"
                        :value="plan.id"
                        :key="plan.id">
                        {{ plan.title }}
                    </option>
                </b-select>
            </b-field>
            <b-field label="Expiration date">
                <b-datepicker
                    v-model="newLicense.expired"
                    :date-formatter="dateFormatter"
                    class="user-licenses__datepicker"
                    locale="en-US"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    trap-focus>
                </b-datepicker>
            </b-field>
            <div class="user-licenses__buttons">
                <button type="button" class="button submit-btn" @click="createLicense()">CREATE LICENSE</button>
            </div>
        </div>
    </div>
</template>

<script>
    import dayjs from 'dayjs';
    import {buildQuery} from '../libs/query.js';
    const DATE_FORMAT = 'DD MMM YYYY';
    const query = buildQuery({ baseURL: 'https://b.8b.com/api' });
    const loadPlans = (function() {
        let promise;
        return function() {
            if (!promise) {
                promise = query('/v1/plans', { method: 'GET' }).then(function({ data }) {
                    const plans = data.reduce((plans, plan) => {
                        return Object.assign(plans, { [plan.planId]: {
                            id: plan.planId,
                            title: plan.planTitle
                        } });
                    }, {});
                    return {
                        getPlan: (planId) => plans[planId] || ({ id: planId, title: `#${planId}` }),
                        allPlans: Object.values(plans).filter((plan) => /8bio/i.test(plan.title))
                    };
                });
            }
            return promise;
        };
    })();
    export default {
        name: 'UserLicenses',
        props: ['userId'],
        watch: {
            userId: function(id) {
                this.reset();
                id && this.renderUserLicenses(id);
            }
        },
        created: function() {
            this.userId && this.renderUserLicenses(this.userId);
        },
        methods: {
            reset() {
                this.plans = null;
                this.userLicenses = null;
                return this;
            },
            createLicense() {
                const loader = this.$buefy.loading.open();
                query(`/v1/users/${this.userId}/licenses`, { method: 'POST', data: {
                    planId: this.newLicense.planId,
                    licenseType: 'pay',
                    providerName: 'none',
                    licenseIssuerId: this.userId,
                    licenseProlong: false,
                    licenseExpired: this.newLicense.expired.getTime()
                }}).then(() => {
                    this.reset().renderUserLicenses(this.userId);
                }, (err) => {
                    console.error(err);
                    if (err.response.data && err.response.data.error) {
                        this.$buefy.notification.open({
                            duration: 3000,
                            message: err.response.data.error.message,
                            position: 'is-top',
                            type: 'is-danger'
                        });
                    }
                }).finally(() => loader.close());
            },
            cancelLicense(licensesId) {
                if (!confirm('Are you sure you want to cancel this license?')) return;
                const loader = this.$buefy.loading.open();
                query(`/v1/licenses/${licensesId}`, { method: 'PUT', data: { licenseStatus: 'canceled' } }).then(() => {
                    this.reset().renderUserLicenses(this.userId);
                }, (err) => {
                    console.error(err);
                    if (err.response.data && err.response.data.error) {
                        this.$buefy.notification.open({
                            duration: 3000,
                            message: err.response.data.error.message,
                            position: 'is-top',
                            type: 'is-danger'
                        });
                    }
                }).finally(() => loader.close());
            },
            renderUserLicenses(id) {
                loadPlans().then(({ allPlans, getPlan }) => {
                    this.newLicense = {
                        planId: allPlans[0].id,
                        expired: dayjs().add(1, 'year').toDate()
                    };
                    return query(`/v1/users/${ id }/licenses`, { method: 'GET' })
                        .then(({data: licenses}) => {
                            if (id !== this.userId) {
                                return;
                            }
                            if (!licenses.length) {
                                this.$emit('update-user-type', 'free');
                                return;
                            }
                            this.userLicenses = licenses.map((row) => {
                                const isExpired = Date.now() > (new Date(row.licenseExpired)).getTime();
                                const isCanceled = row.licenseStatus === 'canceled';
                                return {
                                    id: row.licenseId,
                                    status: row.licenseStatus,
                                    plan: getPlan(row.licensePlanId),
                                    created: dayjs(row.licenseCreated).format(DATE_FORMAT),
                                    expired: dayjs(row.licenseExpired).format(DATE_FORMAT),
                                    isActual: row.licenseStatus === 'active' && !isExpired,
                                    isCanceled,
                                    isExpired
                                };
                            });
                            this.$emit(
                                'update-user-type',
                                this.userLicenses.some((license) => license.isActual) ? 'premium' : 'free'
                            );
                        })
                        .finally(() => id === this.userId && (this.plans = allPlans));
                }).catch(console.error);
            },
            dateFormatter(date) {
                return dayjs(date).format(DATE_FORMAT);
            }
        },
        data() {
            return {
                plans: null,
                userLicenses: null,
                newLicense: {}
            };
        }
    }
</script>

<style scoped>
    .user-licenses {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;-webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        -o-flex-direction: row;
        flex-direction: row;
        flex-wrap: wrap;
        width: 400px;
        padding: 20px;
    }
    .user-licenses__title {
        font-weight: bold;
        text-align: center;
        width: 100%;
    }
    .user-licenses__item {
        display: flex;
        flex-direction: column;
        flex-basis: 340px;
        padding: 10px;
        margin: 5px;
        border-radius: 5px;
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
        cursor: pointer;
    }
    .user-licenses__period {
        font-size: 14px;
    }
    .user-licenses__period code {
        color: #000;
    }
    .user-licenses__buttons {
        margin-top: 10px;
    }
    .user-licenses__form {
        background: #f0f0f0;
    }
    .user-licenses__select,
    .user-licenses__datepicker {
        width: 169px;
        margin: 0 auto;
    }
</style>
