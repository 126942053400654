<template>
    <div style="width: 100%;">
        <div class="row text-center logo-row">
            <img src="../assets/loader.svg" v-if="!formStage">
        </div>
        <form class="b-login" v-if="formStage" v-on:submit.prevent="nextHandler">
            <div class="b-social-auth row" v-if="formStage==1">
                <label for="email" class="b-login__label col-xs-12">{{ 'Login/Sign up' }}</label>
                <div class="col-xs-12 col-sm-8" v-on:click="googleClick">
                    <a
                        class="btn btn-default b-social-auth__button b-social-auth__button_google"
                    >{{ 'Login with Google' }}</a>
                </div>
            </div>

            <!-- email -->
            <div
                class="form-group row email-inpit"
                v-if="formStage==1||formStage==2"
                v-bind:style="{display:formStage==1? 'flex': 'none'}"
            >
                <div class="col-xs-12">
                    <p class="b-social-auth__or">{{ 'or' }}</p>
                </div>
                <div class="input-wrap col-xs-12 col-sm-8">
                    <input
                        type="email"
                        class="form-control b-login__input"
                        required
                        id="email"
                        name="username"
                        :placeholder="'Enter Email'"
                        v-on:keyup.13="nextHandler"
                        v-model="values.email"
                        v-on:change="loginInfoText=''"
                    >
                </div>
                <div class="col-xs-12 col-sm-8">
                    <small
                        class="form-text b-login__info"
                        :class="loginInfoError ? 'text-danger' : 'text-muted'"
                    >{{ loginInfoText }}</small>
                </div>
            </div>

            <!-- password -->
            <div class="form-group row password" v-if="formStage==2">
                <label
                    for="password"
                    class="b-login__label col-xs-12"
                >{{ passwordHeading, {email: values.email} }}</label>
                <div class="input-wrap col-xs-12 col-sm-8">
                    <input
                        type="password"
                        class="form-control b-login__input"
                        required
                        id="password"
                        name="password"
                        :placeholder="'Enter Password'"
                        v-on:keyup.13="nextHandler"
                        v-model="values.password"
                    >
                </div>
                <div class="col-xs-12 col-sm-8">
                    <small
                        class="form-text b-login__info hint"
                        :class="passwordInfoError ? 'text-danger' : 'text-muted'"
                    >{{ passwordInfoText, {email: values.email} }}</small>
                </div>
            </div>
            <div class="form-group row">
                <div class="input-wrap col-xs-12 col-sm-8"></div>
            </div>

            <div class="row">
                <div class="col-xs-12 col-sm-8 btn-wrap">
                    <b-button 
                        type="is-light"
                        size="is-large"
                        v-on:click.prevent="nextHandler"
                        class="btn btn-default b-login__next pull-right"
                    >Next</b-button>
                </div>
            </div>
        </form>
        <div v-if="!formStage" class="b-loader mt-4 mb-4">
            {{ 'Logging in...' }}
        </div>
    </div>
</template>

<script>
    import Client from '../libs/mbr-client.js';
    import { getCookie, setCookie } from '../libs/cookie.js';

    const API_URL = "https://u.8b.com/api/v1/";
    const mbrClient = new Client("https://u.8b.com/api/v1/");

    const stageLoading = 0,
        stageAccount = 1,
        stagePassword = 2,
        stageSignedIn = 3;

    function getArgv(name){
        var params = (location.search? location.search.substr(1) + '&': '') + (location.hash? location.hash.substr(1): '');
        var argv = {};
        params
            .split('&')
            .map(item => {
                let name = decodeURIComponent(item.split('=')[0]||'');
                if (name)
                    argv[name] = decodeURIComponent((item.split('=')[1] ||'').replace(/\+/g, '%20'));
            });
            
        if (argv.state)
            try {
                Object.assign(argv, JSON.parse(decodeURIComponent(argv.state)));
            }
            catch(e){}
            
        return name? argv[name]: argv;
    }

    export default {
        data() {
            return {
                isSignIn: false,
                state: {}, // state for redirect
                formStage: stageAccount,
                values: {
                    email: '',
                    password: ''
                },
                //locale: getCookie('language') ? getCookie('language') : getBrowserLanguage(),
                loginInfoError: false,
                loginInfoText: '',
                passwordInfoError: false,
                passwordInfoText: '',
                passwordForgotAlert: false,
                passwordHeading: '',

                redirectLink: window.location.origin + window.location.pathname,
                googleLink_tmpl:
                    'https://accounts.google.com/o/oauth2/v2/auth?response_type=token&client_id=127029165104-0mr0eo7cltaco9bq03lhj3rn03pc8bej.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&prompt=&include_granted_scopes=true',
                facebookLink_tmpl:
                    'https://www.facebook.com/v2.10/dialog/oauth?response_type=code&client_id=347375702376451&scope=email&type=user_agent'
            };
        },
        computed: {
            googleLink: function() {
                return (
                    this.googleLink_tmpl +
                    '&state=' +
                    encodeURIComponent(
                        JSON.stringify(
                            Object.assign({ service: 'google' }, this.state)
                        )
                    ) +
                    '&redirect_uri=' +
                    encodeURIComponent(this.redirectLink)
                );
            },
            facebookLink: function() {
                return (
                    this.facebookLink_tmpl +
                    '&state=' +
                    encodeURIComponent(
                        JSON.stringify(
                            Object.assign({ service: 'facebook' }, this.state)
                        )
                    ) +
                    '&redirect_uri=' +
                    encodeURIComponent(this.redirectLink)
                );
            }
        },
        components: {
            /*BallPulseLoader*/
        },
        methods: {
            nextHandler() {
                if (this.formStage == stageAccount) {
                    // switch to password
                    if (!this.values.email) {
                        this.loginInfoText = 'Enter login';
                        this.loginInfoError = true;
                        return;
                    }

                    this.formStage = stageLoading;
                    this.values.password = '';
                    let email = this.values.email;

                    mbrClient
                        .signup(email)
                        .then(() => {
                            // new account
                            this.passwordInfoText =
                                "Check your email. Your account password is sent to {email}. If you haven't received the email, please check Spam folder";
                            this.passwordHeading = '8b Account Created';
                            this.formStage = stagePassword;
                            //ga_signup(email);
                        })
                        .catch(error => {
                            if (error.code === 'UserAlreadyExists') {
                                // account already exists
                                this.passwordHeading =
                                    'You already have 8b account for {email}';
                                this.passwordInfoText =
                                    'Your password is sent to {email} with the first sign up. Check your email to find it.';
                                this.formStage = stagePassword;
                            } else {
                                this.loginInfoText = error.message;
                                this.loginInfoError = true;
                                this.formStage = stageAccount;
                            }
                        });
                }

                if (this.formStage == stagePassword) {
                    // logedin
                    if (!this.values.password) {
                        this.passwordInfoText = 'Enter Password';
                        this.passwordInfoError = true;
                        return;
                    }

                    //todo login
                    this.formStage = stageLoading;

                    mbrClient
                        .signin(this.values)
                        .then(() => {
                            this.formStage = stageSignedIn;
                            // notice login
                            setCookie('language', this.locale);
                            this.$router.push({ path: '/'});
                            mbrClient.getProfile().then(data => {
                                //ga_login(data.email);
                                this.$emit('signedin');
                            });
                        })
                        .catch(err => {
                            this.formStage = stageAccount;
                            this.loginInfoText = err.message;
                            this.loginInfoError = true;
                        });
                }
            },
            logout: function() {
                console.log('logout...');

                mbrClient
                    .signout()
                    .then(() => {
                        console.log('ok');
                    })
                    .catch(() => {
                        console.log('fail');
                    });
            },
            forgot: function() {
                mbrClient.lostpassword(this.values.email);
                this.passwordForgotAlert = true;
            },
            googleClick: function() {
                let _this = this;
                event.preventDefault();
                console.log('run google auth');

                this.$gAuth.signIn()
                .then(user => {
                    let auth = user.getAuthResponse();
                    mbrClient.signinByToken({ service: 'google', token: auth.access_token })
                    .then(res => {
                        this.$router.push({ path: '/'});
                    })
                    .catch(err => {
                        console.log(err);
                    });
                })
                .catch(err => { console.log(err); });
            }
        },

        checkAuthorized() {
            return new Promise((resolve, reject) => {
                let accessToken = getArgv('access_token'),
                    state = getArgv('state');

                if (accessToken && state) {
                    state = JSON.parse(decodeURIComponent(state));

                    this.formStage = stageLoading;
                    mbrClient
                        .signinByToken({
                            service: state.service,
                            token: accessToken
                        })
                        .then(data => {
                            check();
                        });
                } else check();

                function check() {
                    mbrClient
                        .isAuthorized()
                        .then(ok => {
                            resolve(ok);
                        })
                        .catch(err => {
                            reject(err);
                        });
                }
            });
        },

        getProfile() {
            return mbrClient.getProfile();
        },

        getAuthToken() {
            return mbrClient.getAuthToken();
        }
    };
</script>

<style lang="scss">
    @import './LoginForm';
    .b-loader {
        text-align: center;
    }
    .b-login {
        max-width: 400px;
        margin: 0 auto;
    }
    .b-footer {
        text-align: center;
    }
    .b-login__input{
        vertical-align: middle;
        display: inline-block;
        height: auto;
        font-size: 1em;
        padding: 16px;
        line-height: 1.3333333;
        color: #555;
        background-color: #fff;
        background-image: none;
        border-radius: 6px;
    }
    .b-login__info{
        display: block;
        padding: 10px;
    }
    .b-social-auth__or{
        font-weight: 700;
        color: #e0e0e0;
        font-size: 18px;
    }
    .email-inpit{
        display: flex;
        flex-direction: column;
    }
    .email-inpit .input-wrap{
        margin: 15px 0 0;
    }
    .email-inpit .input-wrap input{
        border: 1px solid #ddd!important;
        box-shadow: 0 0.4375rem 2.25rem rgba(0,0,0,.1);
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .3s,-webkit-box-shadow ease-in-out .3s;
    }
</style>