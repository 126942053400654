/**
 * Get cookie by cookie name
 * @param {string} name - Cookie name
 * @return {string} Returned cookie value
 */
function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

/**
 * Set Cookie value by Cookie name
 * @param {String} name - Cookie name
 * @param {String} value - Cookie Value
 */
function setCookie(name, value) {
    document.cookie = name + "=" + encodeURIComponent(value);
}

export {
    getCookie,
    setCookie
}