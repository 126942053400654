<template>
    <section class="container mt-6">
        <div class="buttons has-addons">
            <button @click="selectedTab = 'sites'" class="button" :class="selectedTab==='sites' && 'is-success is-selected'">Sites</button>
            <button @click="selectedTab = 'users'" class="button" :class="selectedTab==='users' && 'is-success is-selected'" :disabled="!espUsers">Users</button>
        </div>
        <b-table v-if="selectedTab=='sites'" :data="allSites.items || []">
            <b-table-column field="owner.name" centered label="Owner" v-slot="props">
                <div class="link" @click="findUser(props.row.owner.email)">
                    {{ props.row.owner.email }}
                </div>
            </b-table-column>
            <b-table-column field="owner.publicUrl" centered label="Public URL" v-slot="props">
                <div class="link" @click="open(props.row.publicUrl)">
                    {{ props.row.publicUrl }}
                </div>
            </b-table-column>
            <b-table-column width="200" field="timeLastAccess" centered label="Last access via API" v-slot="props">
                <div class="">
                    {{ new Date(props.row.timeLastAccess).toLocaleDateString('en-GB') }}
                </div>
            </b-table-column>
            <b-table-column width="200" field="Type" centered label="Type" v-slot="props">
                <div class="">
                    {{ props.row.licenses.length ? 'premium' : 'free' }}
                </div>
            </b-table-column>
        </b-table>
        <b-pagination
            v-if="selectedTab === 'sites'"
            @change="setPage(current)"
            class="mt-5 mb-6"
            :total="total"
            v-model="current"
            :range-before="rangeBefore"
            :range-after="rangeAfter"
            :order="order"
            :size="size"
            :simple="isSimple"
            :rounded="isRounded"
            :per-page="perPage"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :page-input="hasInput"
            :page-input-position="inputPosition"
            :debounce-page-input="inputDebounce">
        </b-pagination>
        <b-table v-if="selectedTab=='users'" :data="espUsers.items || []">
            <b-table-column field="email" centered label="Owner" v-slot="props">
                <div class="link" @click="findUser(props.row.email)">
                    {{ props.row.email }}
                </div>
            </b-table-column>
            <b-table-column field="status" centered label="Status" v-slot="props">
                <div class="link">
                    {{ props.row.status }}
                </div>
            </b-table-column>
        </b-table>
        <b-pagination
            v-if="selectedTab === 'users'"
            @change="setPage(espCurrent)"
            class="mt-5 mb-6"
            :total="espUsersTotal"
            v-model="espCurrent"
            :range-before="rangeBefore"
            :range-after="rangeAfter"
            :order="order"
            :size="size"
            :simple="isSimple"
            :rounded="isRounded"
            :per-page="perPage"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :page-input="hasInput"
            :page-input-position="inputPosition"
            :debounce-page-input="inputDebounce">
        </b-pagination>
    </section>
</template>

<script>
import query from '../libs/query.js';

import Client from '../libs/mbr-client.js';
const mbrClient = new Client("https://u.8b.com/api/v1/");

    export default {
        name: 'AllSites',
        props: ['selectUser', 'sitesState'],
        methods: {
            renderUserSites() {
                if (this.sitesState.type === 'all' || this.selectedTab === 'sites') {
                    const loadingComponent = this.$buefy.loading.open();
                    query(`/v2/sites?page=${this.current ? this.current: 1}&perPage=15`, { method: 'GET' })
                    .then((sites) => {
                        console.log(sites)
                        loadingComponent.close();
                        if (sites.count === 0) {
                            return;
                        }
                        this.allSites = sites;
                        this.total = sites.count
                    })
                    .catch(console.error);
                } else if(this.sitesState.type !== 'all' || this.selectedTab === 'users') {
                    const loadingComponent = this.$buefy.loading.open();
                    mbrClient.getEspUsers(this.sitesState.esp, this.espCurrent).then(users => {
                        loadingComponent.close();
                        if (users.count === 0) {
                            return;
                        }
                        this.espUsers = users;
                        this.espUsersTotal = users.count
                    });
                }

            },

            findUser(name) {
                this.selectUser(name)
            },

            open(url) {
                window.open(url, '_blank', 'noreferrer,popup=false');
            },
            setPage() {
                this.renderUserSites()
            }
        },
        watch: {
            sitesState: {
                handler: function(newVal) {
                    if (!newVal) return;
                    if (newVal.esp) this.selectedTab = 'users'
                    this.renderUserSites()
                },
                deep: true
            }
        },
        created: function(){
            this.renderUserSites();
        },
        data() {
            return {
                allSites: Object,
                espUsers: Object,
                selectedTab: 'sites',
                total: 200,
                espUsersTotal: 200,
                current: 1,
                espCurrent: 1,
                perPage: 15,
                rangeBefore: 3,
                rangeAfter: 1,
                order: '',
                size: '',
                isSimple: false,
                isRounded: false,
                hasInput: true,
                prevIcon: 'chevron-left',
                nextIcon: 'chevron-right',
                inputPosition: '',
                inputDebounce: ''
            }
        }
    }
</script>

<style scoped>
.link {
    cursor: pointer;
    color: rgba(52, 146, 255, 1);
    transition: 0.2s
}
.link:hover {
    color: rgba(52, 146, 255, 0.6)
}
</style>
