<template>
    <div v-if="userSites" class="user-sites">
        <div class="user-sites__item" :key="item.id" v-for="item in userSites" @click="open(item.publicUrl)">
            <div>
                <small> id: {{ item.id }}</small>
            </div>
            <div class="public-url-wrap">
                <!-- <b-tooltip :label="item.publicUrl" position="is-bottom" type="is-dark" animated>
                    <span class="public-url"> {{ item.publicUrl }}</span>
                </b-tooltip> -->
                <span class="public-url"> {{ item.publicUrl }}</span>
                <b-field><b-tag :type="getTagType(item.licenseType)">{{ item.licenseType }}</b-tag></b-field>
            </div>
        </div>
    </div>
</template>

<script>
    import query from '../libs/query.js';
    export default {
        name: 'UsersSites',
        props: ['userId'],
        watch: {
            userId: function(id) {
                this.userSites = null;
                id && this.renderUserSites(id);
            }
        },
        created: function() {
            this.userId && this.renderUserSites(this.userId);
        },
        methods: {
            getTagType(licenseType) {
                return licenseType === 'premium' ? 'is-warning' :
                       licenseType === 'canceled' ? 'is-danger' :
                       licenseType === 'expired' ? 'is-dark' :
                       licenseType === 'trial' ? 'is-info' : 'is-success';
            },
            getLicenseType(site) {
                if (!(site.licenses && site.licenses.length)) return 'free';
                return site.licenses.some((item) => item.status === 'active' && item.type === 'pay' && !item.isExpired) ? 'premium' :
                       site.licenses.some((item) => item.status === 'active' && item.type === 'pay' && item.isExpired) ? 'expired' :
                       site.licenses.some((item) => item.type === 'pay') ? 'canceled' : 'trial';
            },
            renderUserSites(id) {
                query(`/v1/users/${ id }/sites`, { method: 'GET' })
                .then((sites) => {
                    if (id !== this.userId || sites.count === 0) {
                        return;
                    }
                    this.userSites = sites.items.map((site) => Object.assign(site, {licenseType: this.getLicenseType(site)}));
                    if (this.userSites.some((site) => site.licenseType === 'premium')) {
                        this.$emit('update-user-type', 'premium');
                    }
                })
                .catch(console.error);
            },
            open(url) {
                window.open(url, '_blank');
            }
        },
        data() {
            return {
                userSites: null
            };
        }
    }
</script>

<style scoped>
    .user-sites{
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;-webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        -o-flex-direction: row;
        flex-direction: row;
        flex-wrap: wrap;
        width: 400px;
        padding: 20px;
    }
    .user-sites__item{
        display: flex;
        flex-direction: column;
        flex-basis: 170px;
        padding: 10px;
        margin: 5px;
        border-radius: 5px;
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
        cursor: pointer;
    }
    .user-sites__label{
        padding-right: 5px;
    }
    small{
        color: #a2a2a2;
    }
    .public-url{
        display: block;
        color: black;
        font-size: 14px;
        max-width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .public-url-wrap{
    }
</style>
