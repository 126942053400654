<template>
    <div class="user-sites">
        <div class="user-sites__item" :key="item.id" v-for="item in userSites" @click="open(item.publicUrl)">
            <iframe :src=item.publicUrl frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
    import query from '../libs/query.js';
    export default {
        name: 'UserSitesIframe',
        props: ['userId'],
        watch: {
            userId: function(id) {
                this.userSites = null;
                id && this.renderUserSites(id);
            }
        },
        created: function() {
            this.userId && this.renderUserSites(this.userId);
        },
        methods: {
            getTagType(licenseType) {
                return licenseType === 'premium' ? 'is-warning' :
                       licenseType === 'canceled' ? 'is-danger' :
                       licenseType === 'expired' ? 'is-dark' :
                       licenseType === 'trial' ? 'is-info' : 'is-success';
            },
            getLicenseType(site) {
                if (!(site.licenses && site.licenses.length)) return 'free';
                return site.licenses.some((item) => item.status === 'active' && item.type === 'pay' && !item.isExpired) ? 'premium' :
                       site.licenses.some((item) => item.status === 'active' && item.type === 'pay' && item.isExpired) ? 'expired' :
                       site.licenses.some((item) => item.type === 'pay') ? 'canceled' : 'trial';
            },
            renderUserSites(id) {
                query(`/v1/users/${ id }/sites`, { method: 'GET' })
                .then((sites) => {
                    if (id !== this.userId || sites.count === 0) {
                        return;
                    }
                    this.userSites = sites.items.map((site) => Object.assign(site, {licenseType: this.getLicenseType(site)}));
                    if (this.userSites.some((site) => site.licenseType === 'premium')) {
                        this.$emit('update-user-type', 'premium');
                    }
                })
                .catch(console.error);
            },
            open(url) {
                window.open(url, '_blank');
            }
        },
        data() {
            return {
                userSites: null
            };
        }
    }
</script>

<style scoped>
.user-sites {
    margin:30px;
}
.user-sites__item {
    margin-bottom: 30px;
}
.user-sites__item iframe {
    width: 100%;
    height: 600px;
}
</style>
