import Vue from 'vue';
import Router from 'vue-router';
import App from '../app.vue';
import Login from '../components/Login.vue';
import AdminPanel from '../components/AdminPanel.vue';

let userNotLoggedIn = true;

Vue.use(Router);

let router = new Router({
	routes: [
		{
			path: '/',
			name: 'mainPath',
			component: AdminPanel
		},
		{
			path: '/login',
			name: 'loginPath',
			component: Login
		}
	]
});

// router.beforeEach((to, from, next) => {
//   // if (to.meta.auth && userNotLoggedIn) {
//   if (userNotLoggedIn) {
//   	// console.log('ooo');
//    //  next('/login');
//   }    
//   else {
//     next();
//   }
// });

export default router;